body {
  height: 100vh;
}

.container {
  height: 100%;
}

.navbar {
  background-color: #272934;
}

.card {
  background-color: #1e283a;
}

.list-group-flush > .list-group-item {
  background-color: #1e283a;
}

.btn.btn-sbot {
  color: #ffffff;
  background-color: #fd511a;
}

.border-sbot {
  border-color: #fd511a !important;
}

footer {
    background-color: #272934;
}
